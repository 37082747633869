.groups-description {
    max-width: 1170px;
    margin: 0 auto;
  }
  
  .groups-description .groups-tags {
    padding: 40px 15px;
  }
  
  .groups-description .groups-tags li {
    background-color: #dcdde1;
    border-radius: 10px;
    margin: 5px;
    padding: 10px;
    width: auto;
    display: inline-block;
  }
  
  @media (min-width: 768px) {
    .groups-description .groups-list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 0 0 40px;
    }
  }

  .calendar__title {
    text-align: center;
    grid-column: 1 / span 5;
    text-transform: uppercase;
    font-size: 25px;
    padding: 20px 0;
    font-weight: bold;
  }
  
  .calendar__header {
    background-color: #fbc531;
    text-transform: uppercase;
    text-align: center;
    padding: 7px;
    font-weight: bold;
    border-radius: 5px;
    display: none;
  }
  
  @media (min-width: 576px) {
    .calendar__header {
      display: initial;
    }
  }
  
  .calendar__item {
    background-color: #ebecf0;
    border-radius: 5px;
    overflow: hidden;
    min-height: 85px;
    margin: 20px;
  }
  
  @media (min-width: 576px) {
    .calendar__item {
      margin: 0;
    }
  }
  
  .calendar__item:hover {
    background-color: #dbdbdf;
  }

  .item__dayGroup {
    background-color: #fbc531;
    padding: 5px 10px;
  }
  
  @media (min-width: 576px) {
    .item__dayGroup {
      background-color: transparent;
      padding: 5px 10px;
    }
  }
  
  .item__day {
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
  }
  
  .item__dayName {
    font-size: 18px;
    margin-right: 5px;
    font-weight: bold;
    display: inline-block;
  }
  
  @media (min-width: 576px) {
    .item__dayName {
      display: none;
    }
  }
  
  .item__card {
    background-color: #ffffff;
    margin-top: 5px;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    position: relative;
  }
  
  .card__category {
    border-radius: 5px;
    font-weight: bold;
    padding: 5px 10px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 14px;
  }
  
  .card__description {
    padding: 5px 0;
    margin: 5px 0;
  }
  
  .card__time {
    font-weight: 600;
    font-size: 14px;
  }

  .vacio {
    display: none;
  }
  
  @media (min-width: 576px) {
    .vacio {
      display: initial;
    }
  }

  .calendar {
    margin: 30px 20px;
  }
  
  @media (min-width: 576px) {
    .calendar {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 10px;
    }
  }

  .live {
    background-color: #f80902;
    color: white;
  }
  .estreno {
    background-color: #27ae60;
    color: white;
  }
  

  .calendar__button {
    background-color: var(--color1); /* #51C6E6 */
    color: #fff; /* blanco */
    border: none; /* sin borde */
    padding: 10px 20px; /* padding */
    font-size: 16px; /* tamaño de fuente */
    cursor: pointer; /* cursor en forma de mano */
    border-radius: 5px; /* esquinas redondeadas */
    transition: background-color 0.3s ease; /* transición suave */
    /* position: absolute; */
    top: 10px;
    right: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border: 1px solid var(--color3); /* #27326C */
  }
  
  .calendar__button:hover {
    background-color: var(--color2); /* #EA498D */
  }
  
  .calendar__button:active {
    background-color: #C1356D; /* un tono más oscuro de #EA498D */
  }